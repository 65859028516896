
:root {
  --space: #f2f2f2;
  --main-dragon: #3e746b;
  --sec-dragon: #355f49;
  --main-soil: #85723e;
  --sec-soil: #cbbb8d;
  --bright-soil: #bb9543;
  --line: #252d2d;
  --shadow: #7a857e;
}

.ui.button.toggle.active, .ui.buttons .button.toggle.active, .ui.toggle.buttons .active.button {
  background-color: var(--main-dragon) !important;
}

.ui.button.toggle.active:hover {
  background-color: var(--sec-dragon) !important;
}

.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
    background-color: var(--bright-soil)!important;
}

.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
    background-color: var(--bright-soil)!important;
}

li.action-item:not(:last-child) {
   margin-bottom: 0.6em;
}

div.experience-item:not(:first-child) {
   margin-top: 4em;
}

.ui.header.education-item {
   margin-top: 2em;
}
